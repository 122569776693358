import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useToggle } from "hooks";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import {
  AwaitingTransportDetails,
  AwaitingTransportStatus,
} from "api/wms/awaiting-transports/models";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { dateUtils, queryString } from "utilities";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { wmsAwaitingTransportsApi } from "api/wms/awaiting-transports/api";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { AddOrdersBySignaturesModal } from "./addOrdersBySignaturesModal/AddOrdersBySignaturesModal";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";

interface Props {
  close: () => void;
  awaitingTransport: AwaitingTransportDetails;
}

export const PanelHeader = ({ close, awaitingTransport }: Props) => {
  const patchMutation = wmsAwaitingTransportsActions.usePatchAwaitingTransport();
  const addOrdersBySignaturesModal = useToggle();
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <>
      <RightPanelHeader>
        <div className="d-flex align-items-center gap-2">
          {awaitingTransport.status === AwaitingTransportStatus.FINISHED ? (
            <Button
              className="text-uppercase"
              disabled={Boolean(awaitingTransport.unloading)}
              size="small"
              startIcon={MdiCheck}
              onClick={() => {
                const status = (() => {
                  if (!awaitingTransport.numberOfScannedPackages) {
                    return AwaitingTransportStatus.NOT_STARTED;
                  }
                  return AwaitingTransportStatus.IN_PROGRESS;
                })();
                patchMutation.mutate({
                  status,
                  finishedAt: null,
                  id: awaitingTransport.id,
                });
              }}
              variant="success"
            >
              Zakończono
            </Button>
          ) : (
            <Button
              className="text-uppercase"
              isLoading={patchMutation.isLoading}
              onClick={() =>
                patchMutation.mutate({
                  status: AwaitingTransportStatus.FINISHED,
                  finishedAt: dateUtils.formatDateToIso(new Date()),
                  id: awaitingTransport.id,
                })
              }
              size="small"
              startIcon={MdiCheck}
              theme="light"
              variant="outline"
            >
              Zakończ
            </Button>
          )}
          {isTriggerElementHidden && (
            <SlidingHeaderWrapper className="noWrap">
              {awaitingTransport.signature}
            </SlidingHeaderWrapper>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end flex-1 gap-1">
          <Button
            size="small"
            variant="gray"
            onClick={addOrdersBySignaturesModal.open}
            endIcon={MdiAdd}
          >
            Dodaj zamówienia
          </Button>
          <FileDownloadHandler
            factoryFn={() =>
              wmsAwaitingTransportsApi.awaitingTransportFileFactory.packagesLabels(
                queryString.stringify({ awaitingTransportId: awaitingTransport.id }),
              )
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz etykiety">
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={event => {
                    event.stopPropagation();
                    download();
                  }}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <FileDownloadHandler
            factoryFn={() =>
              wmsAwaitingTransportsApi.awaitingTransportFileFactory.awaitingTransportPdf(
                awaitingTransport.id,
                awaitingTransport.signature,
              )
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz pdf">
                <IconButton
                  icon={MdiDownloadPdf}
                  isLoading={isLoading}
                  onClick={event => {
                    event.stopPropagation();
                    download();
                  }}
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </RightPanelHeader>
      {addOrdersBySignaturesModal.isOpen && (
        <AddOrdersBySignaturesModal
          close={addOrdersBySignaturesModal.close}
          awaitingTransport={awaitingTransport}
        />
      )}
    </>
  );
};
