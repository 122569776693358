import { UUID } from "api/types";
import { wmsAwaitingTransportsActions } from "api/wms/awaiting-transports/actions";
import { AwaitingTransportListItem } from "api/wms/awaiting-transports/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Pagination } from "hooks/createPaginatedQuery";
import { useState } from "react";
import styles from "./AddAwaitingTransportsToUnloading.module.css";
import { useAwaitingTransportColumns } from "./useAwaitingTransportColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { AddAwaitingTransportsFilters } from "./AddAwaitingTransportsToUnloading";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  awaitingTransports: AwaitingTransportListItem[];
  close: () => void;
  isLoading: boolean;
  isPreviousData: boolean;
  pagination: Pagination;
  setFilter: <
    T extends keyof AddAwaitingTransportsFilters,
    U extends AddAwaitingTransportsFilters[T]
  >(
    name: T,
    value: U,
  ) => void;
}

export const AddAwaitingTransportsToUnloadingContent = ({
  awaitingTransports,
  close,
  isLoading,
  isPreviousData,
  pagination,
  setFilter,
}: Props) => {
  const postUnloadingMutation = wmsAwaitingTransportsActions.usePostUnloadingFromAwaitingTransports();
  const [selectedAwaitingTransports, setSelectedAwaitingTransports] = useState<
    AwaitingTransportListItem[]
  >([]);

  const isAwaitingTransportSelected = (id: UUID): boolean =>
    selectedAwaitingTransports.some(awaitingTransport => awaitingTransport.id === id);

  const setAwaitingTransport = (id: UUID): void => {
    if (isAwaitingTransportSelected(id))
      return setSelectedAwaitingTransports(prevAwaitingTransports =>
        prevAwaitingTransports.filter(awaitingTransport => awaitingTransport.id !== id),
      );
    const awaitingTransportToAdd = awaitingTransports.find(
      awaitingTransport => awaitingTransport.id === id,
    );
    assertIsDefined(awaitingTransportToAdd);
    setSelectedAwaitingTransports(prevAwaitingTransports => [
      ...prevAwaitingTransports,
      awaitingTransportToAdd,
    ]);
  };

  const areAllSelected = awaitingTransports.every(awaitingTransport =>
    selectedAwaitingTransports.some(
      selectedAwaitingTransport => selectedAwaitingTransport.id === awaitingTransport.id,
    ),
  );

  const setAllAwaitingTransports = (): void => {
    if (areAllSelected) return setSelectedAwaitingTransports([]);
    const notSelectedAwaitingTransports = awaitingTransports.filter(
      awaitingTransport =>
        !selectedAwaitingTransports.some(
          selectedAwaitingTransport => selectedAwaitingTransport.id === awaitingTransport.id,
        ),
    );
    setSelectedAwaitingTransports(prevAwaitingTransports => [
      ...prevAwaitingTransports,
      ...notSelectedAwaitingTransports,
    ]);
  };

  const areSomeAwaitingTransportsSelected = Boolean(
    selectedAwaitingTransports.length &&
      awaitingTransports.some(awaitingTransport =>
        selectedAwaitingTransports.some(
          selectedAwaitingTransport => selectedAwaitingTransport.id === awaitingTransport.id,
        ),
      ),
  );

  const columns = useAwaitingTransportColumns(
    areAllSelected,
    areSomeAwaitingTransportsSelected,
    isAwaitingTransportSelected,
    setAllAwaitingTransports,
    setAwaitingTransport,
  );

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.tableContent}>
          <div className={styles.selectedInfo}>
            <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
              Zaznaczono:
            </Typography>
            <Typography color="neutralBlack88" fontSize="10" fontWeight="700">
              {selectedAwaitingTransports.length}
            </Typography>
          </div>
          <Table
            columns={columns}
            rows={awaitingTransports}
            isLoading={isPreviousData || isLoading}
            error={null}
            pagination={pagination}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            uiSchema={comfortableListUiSchema}
          />
        </div>
      </div>
      <div className="d-flex align-items-center gap-3 p-3 borderTop">
        <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
          Anuluj
        </Button>
        <Button
          className="text-uppercase"
          disabled
          isLoading={postUnloadingMutation.isLoading}
          onClick={() =>
            postUnloadingMutation.mutate(
              {
                awaitingTransportsIds: selectedAwaitingTransports.map(
                  awaitingTransport => awaitingTransport.id,
                ),
              },
              {
                onSuccess: () => close(),
              },
            )
          }
          size="medium"
          variant="deepPurple"
        >
          Dodaj awizacje
        </Button>
      </div>
    </div>
  );
};
