import { UUID } from "api/types";
import { wmsAwaitingTransportsApi } from "api/wms/awaiting-transports/api";
import { AwaitingTransportListItem } from "api/wms/awaiting-transports/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getAwaitingTransportStatusTag } from "constants/awaitingTransport";
import { queryString } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useAwaitingTransportColumns = (
  areAllSelected: boolean,
  areSomeAwaitingTransportsSelected: boolean,
  isAwaitingTransportSelected: (id: UUID) => boolean,
  setAllAwaitingTransports: () => void,
  setAwaitingTransport: (id: UUID) => void,
) => {
  return useCreateTableColumns<AwaitingTransportListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectAwaitingTransports",
        header: () => {
          return (
            <div className="d-flex align-items-end">
              <Checkbox
                checked={areAllSelected}
                indeterminate={areSomeAwaitingTransportsSelected && !areAllSelected}
                onChange={setAllAwaitingTransports}
                size="sx"
              />
            </div>
          );
        },
        size: 25,
        cell: info => {
          const awaitingTransport = info.getValue();
          return (
            <Checkbox
              checked={isAwaitingTransportSelected(awaitingTransport.id)}
              onChange={() => setAwaitingTransport(awaitingTransport.id)}
              size="sx"
            />
          );
        },
      }),
      columnHelper.text(row => row.signature, {
        header: "numer",
        size: 200,
        typographyProps: { fontSize: "16", fontWeight: "700" },
      }),
      columnHelper.text(row => row.customer?.name, {
        header: "kontrahent",
        size: 200,
        typographyProps: { fontSize: "14", fontWeight: "600" },
      }),
      columnHelper.accessor(row => row, {
        header: "paczki",
        size: 60,
        cell: info => {
          const numberOfPackages = info.getValue().numberOfPackages;
          const numberOfScannedPackages = info.getValue().numberOfScannedPackages;
          return (
            <div className="d-flex align-items-center">
              <Typography
                color={numberOfPackages ? "success500" : "neutralBlack100"}
                fontSize="14"
                fontWeight="700"
              >
                {numberOfScannedPackages}
              </Typography>
              <Typography color="neutralBlack100" fontSize="14" fontWeight="700">
                /{numberOfPackages}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.date(row => row.createdAt, {
        header: "utworzono",
        size: 130,
        format: "dd.MM.yyyy, HH:mm",
      }),
      columnHelper.text(row => row.description, {
        header: "opis",
        size: 150,
        typographyProps: { fontSize: "14", fontWeight: "400" },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 120,
        cell: info => {
          const status = info.getValue();

          return getAwaitingTransportStatusTag(status);
        },
      }),
      columnHelper.text(row => row.unloading?.signature, {
        header: "rozładunek",
        size: 150,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.accessor(row => row.id, {
        header: " ",
        id: "labels",
        size: 43,
        cell: info => {
          const id = info.getValue();
          return (
            <FileDownloadHandler
              factoryFn={() =>
                wmsAwaitingTransportsApi.awaitingTransportFileFactory.packagesLabels(
                  queryString.stringify({ awaitingTransportId: id }),
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <Tooltip title="Pobierz etykiety">
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </FileDownloadHandler>
          );
        },
      }),
    ];
  });
};
