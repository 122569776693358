import { AwaitingTransportDetails } from "api/wms/awaiting-transports/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { getAwaitingTransportStatusTag } from "constants/awaitingTransport";

interface Props {
  awaitingTransport: AwaitingTransportDetails;
}

export const TitleSection = ({ awaitingTransport }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {awaitingTransport.signature}
        </Typography>
        {getAwaitingTransportStatusTag(awaitingTransport.status)}
      </div>
    </RightPanelSection>
  );
};
