import { queryFetch } from "apiConnectors/queryFetch";
import {
  AddOrdersToAwaitingTransportResponse,
  AwaitingTransportDetails,
  AwaitingTransportGroupDetails,
  AwaitingTransportGroupListItem,
  AwaitingTransportListItem,
} from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { Pagination, UUID } from "api/types";
import { wmsAwaitingTransportsKeys } from "./keys";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";
import { dateFns } from "utilities";

const getAwaitingTransports = (
  search: string = "",
): ApiFetcher<Pagination<AwaitingTransportListItem>> => ({
  key: wmsAwaitingTransportsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/items" + search,
    }),
});

const getAwaitingTransport = (
  id: AwaitingTransportDetails["id"],
): ApiFetcher<AwaitingTransportDetails> => ({
  key: wmsAwaitingTransportsKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/items/" + id,
    }),
});

const patchAwaitingTransport = (data: Assign<Partial<AwaitingTransportDetails>, { id: UUID }>) => {
  return queryFetch<AwaitingTransportDetails>({
    method: "PATCH",
    url: "/wms/awaiting-transports/items/" + data.id,
    data: parsePatchData(data),
  });
};

const postAwaitingTransport = () =>
  queryFetch<void>({
    method: "POST",
    url: "/wms/awaiting-transports/items",
  });

const getAwaitingTransportGroups = (
  search: string = "",
): ApiFetcher<Pagination<AwaitingTransportGroupListItem>> => ({
  key: wmsAwaitingTransportsKeys.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/groups" + search,
    }),
});

const getAwaitingTransportGroup = (
  id: AwaitingTransportDetails["id"],
): ApiFetcher<AwaitingTransportGroupDetails> => ({
  key: wmsAwaitingTransportsKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/awaiting-transports/groups/" + id,
    }),
});

const postUnloadingFromAwaitingTransport = (data: {
  awaitingTransportsIds: AwaitingTransportDetails["id"][];
}) =>
  queryFetch<void>({
    method: "POST",
    url: "/wms/awaiting-transports/create-unloading",
    data,
  });

const addOrdersToAwaitingTransport = (data: {
  awaitingTransport: AwaitingTransportDetails["id"];
  externalIds: string[];
}) =>
  queryFetch<AddOrdersToAwaitingTransportResponse>({
    method: "POST",
    url: "orders/add-orders-to-awaiting-transport-base-on-external-ids",
    data,
  });

const awaitingTransportFileFactory = (() => {
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }

  return {
    packagesLabels: (search: string = "") => ({
      url: `wms/awaiting-transports/get-labels${search}`,
      name: `etykiety-${getDate()}`,
    }),
    awaitingTransportPdf: (id: AwaitingTransportDetails["id"], signature: string) => ({
      url: `/wms/awaiting-transports/loaded-packages-pdf/${id}`,
      name: `Awizacja-${signature}-${getDate()}`,
    }),
  };
})();

export const wmsAwaitingTransportsApi = {
  getAwaitingTransport,
  getAwaitingTransports,
  postAwaitingTransport,
  patchAwaitingTransport,
  getAwaitingTransportGroups,
  getAwaitingTransportGroup,
  awaitingTransportFileFactory,
  addOrdersToAwaitingTransport,
  postUnloadingFromAwaitingTransport,
};
