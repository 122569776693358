import { CountryCode } from "CONSTANTS";
import { ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";

export enum AwaitingTransportStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export interface AwaitingTransportListItem {
  id: UUID;
  signature: string;
  description: string;
  customer: { id: number; name: string } | null;
  status: AwaitingTransportStatus;
  startedAt: ISODateTime | null;
  finishedAt: ISODateTime | null;
  collaborators: UserWithInitials[];
  unloading: { result: UUID; signature: string } | null;
  createdAt: ISODateTime;
  numberOfPackages: number;
  numberOfScannedPackages: number;
  createdBy: UserWithInitials | null;
  updatedAt: ISODateTime;
}

export interface AwaitingTransportDetails extends AwaitingTransportListItem {}

export enum GroupStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}
export interface AwaitingTransportGroupListItem {
  id: UUID;
  signature: string;
  awaitingTransport: AwaitingTransportDetails["id"];
  name: string;
  position: number;
  status: GroupStatus;
  sourceId: string;
  sourceKind: string;
  sourceExternalId: string;
  address: { countryCode: CountryCode } | null;
  collaborators: UserWithInitials[];
  loadedAt: ISODateTime | null;
  createdAt: ISODateTime;
  updatedAt: ISODateTime;
  numberOfPackages: number;
  loadedPackages: number;
}

export interface AwaitingTransportGroupDetails extends AwaitingTransportGroupListItem {}

export interface AddOrdersToAwaitingTransportResponse {
  addedToAwaitingTransport: {
    externalId: string;
    id: number;
    signature: string;
  }[];
  createdAwaitingTransport: {
    id: UUID;
    signature: string;
  } | null;
  inDifferentAwaitingTransport: {
    order: { id: number; signature: string; externalId: string };
    awaitingTransport: { id: UUID; signature: string } | null;
  }[];
  missingIndexes: { id: number; signature: string; externalId: string }[];
  missingOrders: { id: number; signature: string; externalId: string }[];
  missingPackages: { id: number; signature: string; externalId: string }[];
}
