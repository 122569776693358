import { useMutation } from "hooks/useMutation";
import { wmsAwaitingTransportsApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { getAnyErrorKey } from "utilities";
import { createApiQuery } from "hooks/createApiQuery";
import { wmsAwaitingTransportsKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";

const useGetAwaitingTransports = createPaginatedApiQuery(
  wmsAwaitingTransportsApi.getAwaitingTransports,
);

const useGetAwaitingTransport = createApiQuery(wmsAwaitingTransportsApi.getAwaitingTransport);

const useGetAwaitingTransportGroups = createPaginatedApiQuery(
  wmsAwaitingTransportsApi.getAwaitingTransportGroups,
);

const useGetAwaitingTransportGroup = createApiQuery(
  wmsAwaitingTransportsApi.getAwaitingTransportGroup,
);

const usePostAwaitingTransport = () => {
  return useMutation(wmsAwaitingTransportsApi.postAwaitingTransport, ({ toastr, queryClient }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchAwaitingTransport = () => {
  return useMutation(wmsAwaitingTransportsApi.patchAwaitingTransport, ({ queryUtils }) => ({
    onMutate: args => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        wmsAwaitingTransportsKeys.list(),
        args.id,
        args,
      );
      const prevPanel = queryUtils.handleMutate(wmsAwaitingTransportsKeys.details(args.id), args);
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollbackList(wmsAwaitingTransportsKeys.list(), onMutateReturn.prevList, id);
      queryUtils.rollback(wmsAwaitingTransportsKeys.details(id), onMutateReturn.prevPanel, error);
    },
  }));
};

const usePostUnloadingFromAwaitingTransports = () => {
  return useMutation(
    wmsAwaitingTransportsApi.postUnloadingFromAwaitingTransport,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useAddOrdersToAwaitingTransport = () => {
  return useMutation(
    wmsAwaitingTransportsApi.addOrdersToAwaitingTransport,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

export const wmsAwaitingTransportsActions = {
  useGetAwaitingTransports,
  usePostAwaitingTransport,
  useGetAwaitingTransport,
  usePatchAwaitingTransport,
  useGetAwaitingTransportGroup,
  useGetAwaitingTransportGroups,
  useAddOrdersToAwaitingTransport,
  usePostUnloadingFromAwaitingTransports,
};
